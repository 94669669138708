// import '../App.css'
import { useState } from 'react';
import { Alert, Form, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Backend, ICampaign, LargeScreenDimension, SmallScreenDimension } from '../App';
import UploadResize from './upload_resize';
import billboardImage from '../assets/billboard-ads-small-large.png';


const NewCampaign = () => {
  const ctx = useOutletContext<any>();
  const navigate = useNavigate();
  let state = ctx.state;
  let user = ctx.user;

  let remainingBudget = state.viewBudget - state.viewBudgetUsed;
  const [name, setName] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [numViews, setNumViews] = useState<number>(remainingBudget);
  const [dimension, setDimension] = useState<string>(LargeScreenDimension);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleNameChange = async (event: any) => {
    setName(event.target.value);
  }
  const handleDimensionChange = async (event: any) => {
    if (event.target.value.includes(SmallScreenDimension)) {
      setDimension(SmallScreenDimension)
    } else {
      setDimension(LargeScreenDimension)
    }
  }


  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setShowSpinner(true);
    let token = await user.getIdToken()
    var raw = JSON.stringify({
      "dimension": dimension,
      "name": name,
      "numViews": numViews,
      "fileName": fileName,
      "tenant": state.tenantId,
      "email": user.email
    });

    let fetchResult = await fetch(Backend, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }, body: raw
    });

    let body = await fetchResult.json();
    setShowSpinner(false);
    if (!fetchResult.ok) {
      setErrorMessage("Could not create campaign, error was: " + body.message);
      setShowErrorAlert(true);
    } else {
      const c: ICampaign = {
        id: body.id,
        creator: user.email,
        dimension: dimension,
        name: name,
        numViews: numViews,
        purchasedViews: numViews,
        consumedViews: 0,
        enabled: false,
        email: user.email,
        fileName: fileName,
        tenant: state.tenantId,
        imageUrl: fileName,
        status: "awaiting-approval",
        createTime: null,
        modTime: null
      }
      await ctx.addCampaign(c);
      await ctx.setSuccessMessage("Thank you. Your new campaign has now been sent to our team for approval and will go live shortly. Once approved, your new ad campaign will be live in the Metaverse and appear under the 'Active' tab in this dashboard.")
      navigate(`/`);
    }
  }

  if (remainingBudget <= 0) {
    navigate("/");
    return <Container />;
  }

  return (
    <Container className='p-5 mb-4 rounded-3'>
      <h1 className="header">
        <Button type="submit" className="sc_button" onClick={() => navigate("/")}>
          <FaArrowAltCircleLeft />
        </Button>        /         Get started with a new Campaign
      </h1>

      <hr />

      {showErrorAlert &&
        <Alert variant="danger" onClose={() => { setShowErrorAlert(false) }} dismissible>
          <Alert.Heading>Something went wrong</Alert.Heading>
          <p>
            {errorMessage}
          </p>
        </Alert>
      }

      <Form className='create-container container justify-content-center align-items-center'>
        <Form.Group className="mb-3">
          <Form.Label>
            Name your campaign
          </Form.Label>
          <Form.Control required placeholder="Insert Name Here"
            maxLength={20}
            tabIndex={1}
            value={name}
            onChange={handleNameChange} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Views {numViews}/{remainingBudget}</Form.Label>
          <Form.Range max={remainingBudget} step="500" value={numViews} onChange={event => { setNumViews(Number(event.target.value)) }} />
        </Form.Group>
        <Container>
          <Form.Label>
            Choose which screen type you want to use:
            <img src={billboardImage} width="80%" alt="small-screen-big-screen" />
          </Form.Label>
          <Form.Select onChange={handleDimensionChange} disabled={!!fileName} tabIndex={4}>
            <option>Large Screen ({LargeScreenDimension} px)</option>
            <option>Small Screen ({SmallScreenDimension} px)</option>
          </Form.Select>
        </Container>
        <br />

        <UploadResize dimension={dimension} setFileName={setFileName} />
        {showSpinner &&
          <Container>
            <p>Setting up your campaign...</p>
            <Spinner animation="border" role="status" />
          </Container>
        }
        {!showSpinner &&
          <Button type="submit" className="sc_button" tabIndex={5} disabled={!fileName} onClick={handleSubmit}>Create</Button>
        }
      </Form>
    </Container>
  );
}

export default NewCampaign;

