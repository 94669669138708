import { useEffect, useState } from 'react';
import { Button, Spinner, Toast } from 'react-bootstrap';

import Container from 'react-bootstrap/Container';
import { Trans } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Backend } from '../App';
import ScoreCards from './scorecard';

const ControlCenter = () => {
    const ctx = useOutletContext<any>();
    let state = ctx.state;
    let user = ctx.user;
    let loading = state.campaignHash === "";
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    // this effect needs to stay here as the ControlCenter is the actual component being loaded
    useEffect(() => {
        if (!user) {
            return;
        }

        setShowErrorAlert(false);

        user.getIdToken().then((token: string) =>
            fetch(`${Backend}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(e => new Error(e["message"]))
                    }
                    return response.json();
                })
                .then(data => {
                    if (data instanceof Error) {
                        throw data;
                    }
                    // update the state
                    ctx.onLoad(data);
                }).catch(e => {
                    if (e.message.includes("authorize")) {
                        // load some dummy data
                        ctx.onLoad({
                            allowance: 0,
                            viewBudget: 0,
                            viewBudgetUsed: 0,
                            used: 0,
                            tenantId: "",
                            activeSlots: [],
                        });
                    }
                    console.log(e);
                    setErrorMessage(`${e.message} - could not retrieve campaigns, please try again later.`);
                    setShowErrorAlert(true);
                }));
        // eslint-disable-next-line
    }, [user, state.campaignHash]);


    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setShowErrorAlert(false);

        let token = await user.getIdToken()
        var raw = JSON.stringify({});
        let fetchResult = await fetch(`${Backend}?accept=yep`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }, body: raw
        });

        let body = await fetchResult.json();
        if (!fetchResult.ok) {
            setErrorMessage("Could not accept the terms and conditions, error was: " + body.message);
            setShowErrorAlert(true);
        } else {
            await ctx.setAcceptedTnCs();
            await ctx.setSuccessMessage("Thank you for accepting the terms and conditions! Click 'Create Campaign' to get started in the Metaverse.")
            navigate(`/`);
        }
    }

    return (
        <Container>
            {!user &&
                <Container>
                    <h1 className="header">
                        <Trans i18nKey="page_1_top_header" />
                    </h1>
                    <h3 className="header">
                        <Trans i18nKey="page_1_top_message" />
                    </h3>
                </Container>
            }
            {user &&
                <Container>
                    {loading &&
                        <Container>
                            {showErrorAlert &&
                                <Toast
                                    bg="danger"
                                    onClose={() => setShowErrorAlert(false)}
                                    show={showErrorAlert}>
                                    <Toast.Body className="text-white">{errorMessage}</Toast.Body>
                                </Toast>
                            }
                            {!showErrorAlert &&
                                <Container>
                                    <p>Loading your campaigns...</p>
                                    <Spinner animation="border" role="status" />
                                </Container>
                            }
                        </Container>
                    }
                    {!loading &&
                        <Container>
                            {state.tenantId && !state.termsConditionsAccepted &&
                                <Container className='tnc-container'>
                                    <h2>Welcome</h2>
                                    <p>Before we can get started, please review and accept our <a href="https://nftplazas.com/metaverse-advertising/booking-system-terms-conditions/" target={'_blank'} rel="noreferrer">Terms and Conditions.</a></p>
                                    <Button type="submit" className="sc_button" tabIndex={3} onClick={handleSubmit}>Accept</Button>
                                </Container>
                            }

                            {state.tenantId && state.termsConditionsAccepted &&
                                <ScoreCards />
                            }
                            {!state.tenantId &&
                                <Container>
                                    <h1 className="header">
                                        <Trans i18nKey="page_1_top_header" />
                                    </h1>
                                    <h3 className="header">
                                        <Trans i18nKey="page_1_not_authorized" />
                                    </h3>
                                </Container>
                            }
                        </Container>
                    }

                </Container>
            }
        </Container >
    );
}

export default ControlCenter;

