import firebase from "firebase";
import { render } from "react-dom";
// import mixpanel from 'mixpanel-browser';
// import { hotjar } from "react-hotjar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import withFirebaseAuth from 'react-with-firebase-auth';
import TranslationApp from './App';
import ControlCenter from "./component/control_center";
import NewCampaign from "./component/new_campaign";
import './i18n';
import './index.css';

const firebaseConfig = {
  apiKey: "AIzaSyAsQb_VeB8o_zDYCAMg83O9DPrzCoK5IOQ",
  authDomain: "metabeacon.firebaseapp.com",
  projectId: "metabeacon",
  storageBucket: "metabeacon.appspot.com",
  messagingSenderId: "27929551592",
  appId: "1:27929551592:web:fefac1e3224b05b5055f5d",
  measurementId: "G-45E8ZKEGDL"
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

const createComponentWithAuth = withFirebaseAuth({
  firebaseAppAuth: auth,
  providers: providers
});

const AuthApp = createComponentWithAuth(TranslationApp)

// hotjar.initialize(2976642, 6);
// mixpanel.init('f26f47967ec82bad7470d04024404b99', { debug: true });

render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<AuthApp />}>
        <Route path="" element={<ControlCenter />}/>
        <Route path="new" element={<NewCampaign />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
