import { Alert, Button, Card, Form, Modal } from 'react-bootstrap';

import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import { FaChalkboard, FaPlus, FaSync, FaTrash } from 'react-icons/fa';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Backend, ICampaign } from '../App';


const ScoreCards = () => {
    const ctx = useOutletContext<any>();
    let state = ctx.state;
    let user = ctx.user;

    const navigate = useNavigate();

    const [campaignFilter, setCampaignFilter] = useState("all");
    const [campaignSearch, setCampaignSearch] = useState("");
    const [campaignIdToBeDeleted, setCampaignIdToBeDeleted] = useState("");
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const handleCampaignFilter = (eventKey: any) => setCampaignFilter(eventKey);
    const handleSearch = (event: any) => setCampaignSearch(event.target.value);
    const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
    const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true);

    // it's either slots that are all used or there's no views remaining
    let filledAllSlots = state.used === state.allowance || state.viewBudgetUsed === state.viewBudget;

    const deleteInBackend = async () => {
        let token = await user.getIdToken()
        let fetchResult = await fetch(`${Backend}?id=${campaignIdToBeDeleted}`,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });

        setCampaignIdToBeDeleted("");
        if (fetchResult.ok) {
            handleCloseDeleteConfirmation();
            await ctx.setSuccessMessage("Thank you. Your new campaign has now been deleted and the remaining view budget was returned to your account.");
            await ctx.onLoad({ refresh: true });
            navigate("/")
        } else {
            let body = await fetchResult.json();
            setErrorMessage("Could not delete campaign, error was: " + body.message);
            setShowErrorAlert(true);
        }
    }

    const filterFunc = (e: ICampaign) => {
        let include = false;
        if (campaignFilter) {
            let filterInclude = true;
            if (campaignFilter === "active") {
                filterInclude = e.numViews > 0 && (e.enabled ?? true) && (!e.status);
            } else if (campaignFilter === "finished") {
                filterInclude = e.numViews <= 0;
            } else if (campaignFilter === "disabled") {
                filterInclude = e.enabled != null && !e.enabled;
            } else if (campaignFilter === "approval") {
                filterInclude = e.status != null && e.status !== "";
            }
            include = filterInclude;
        }

        if (campaignSearch) {
            let searchInclude = e.id.toLowerCase().includes(campaignSearch);
            if (e.name) {
                searchInclude = searchInclude || e.name.toLowerCase().includes(campaignSearch);
            }
            include = include && searchInclude;
        }

        return include;
    }

    return (<Container>
        {showErrorAlert &&
            <Alert variant="danger" onClose={() => { setShowErrorAlert(false) }} dismissible>
                <Alert.Heading>Something went wrong</Alert.Heading>
                <p>
                    {errorMessage}
                </p>
            </Alert>
        }

        <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
            <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav variant="pills" onSelect={handleCampaignFilter} activeKey={campaignFilter}>
                            <Nav.Item>
                                <Nav.Link eventKey="all">All</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="approval">Approval</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="active">Active</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="finished">Finished</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="disabled">Disabled</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Nav className='nav-sep-box'>
                            <Nav.Item>
                                <Button className="sc_button refresh-button" onClick={() => ctx.onLoad({ refresh: true })}><FaSync /></Button>
                            </Nav.Item>
                            {!filledAllSlots &&
                                <Nav.Item>
                                    <Button className="sc_button create-button" onClick={() => navigate("/new")}>Create Campaign</Button>
                                </Nav.Item>
                            }
                        </Nav>
                        <Nav>
                            <Nav.Item>
                                <Form.Control type="text"
                                    className='search-box'
                                    placeholder="Search"
                                    value={campaignSearch}
                                    onChange={handleSearch}
                                />
                            </Nav.Item>
                        </Nav>
                    </Nav>
                </Navbar.Collapse>
                <Nav>
                    <Nav.Item>
                        {state.used}/{state.allowance} used Slots <br />
                        {state.viewBudgetUsed}/{state.viewBudget} used Views
                    </Nav.Item>
                </Nav>
            </Container>
        </Navbar>

        <Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation}>
            <Modal.Header closeButton>
                <Modal.Title>Deleting the campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you really want to delete this campaign? This action is irreversible.</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDeleteConfirmation}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={deleteInBackend}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>

        <Row xs={1} md={4} className="g-4">
            {
                state.campaigns.filter(filterFunc).map(function (object: any, i: number) {
                    return <Col key={"cccol/" + i}>
                        <Card key={"card/" + i} bg="dark" text="white" style={{ margin: '1em' }}>
                            <Card.Img variant="top" src={object.imageUrl} />
                            <Card.Body>
                                <Card.Title>{object.name ?? object.id.substring(0, 10)}</Card.Title>
                                {(object.numViews > 0) &&
                                    <Card.Subtitle>{object.numViews < 0 ? 0 : object.numViews}/{object.purchasedViews} Views</Card.Subtitle>
                                }
                                {(!object.status && object.numViews > 0 && object.createTime) &&
                                    <Card.Subtitle>Started {moment(object.createTime).fromNow()}</Card.Subtitle>
                                }
                                {object.status &&
                                    <Card.Subtitle><i>Awaiting Approval</i></Card.Subtitle>
                                }
                                {(object.numViews <= 0) &&
                                    <Container>
                                        <Card.Subtitle>Ended {moment(object.modTime).fromNow()}</Card.Subtitle>
                                        <Card.Subtitle>Shown {object.consumedViews} times</Card.Subtitle>
                                    </Container>
                                }
                                {(!object.enabled) &&
                                    <Card.Subtitle><i>Disabled</i></Card.Subtitle>
                                }
                            </Card.Body>
                            {!object.status &&
                                <Card.Footer>
                                    <Button
                                        type="submit"
                                        className="sc_button"
                                        onClick={() => window.open(`https://datastudio.google.com/reporting/a7601065-4a4e-4675-a47c-8c54cc28ea5d/page/p_jmfsf10cuc?params=%7B%22campaign%22:%22${encodeURIComponent(object.id)}%22%7D`, '_blank', 'noopener,noreferrer')}>
                                        <FaChalkboard style={{ marginRight: '1em' }} />
                                        View Report
                                    </Button>
                                    {object.enabled &&
                                        <Button
                                            type="submit"
                                            className="sc_button"
                                            style={{ backgroundColor: 'salmon' }}
                                            onClick={() => {
                                                setCampaignIdToBeDeleted(object.id);
                                                handleShowDeleteConfirmation();
                                            }}>
                                            <FaTrash style={{ marginRight: '1em' }} />
                                            Delete
                                        </Button>
                                    }
                                </Card.Footer>
                            }
                        </Card>
                    </Col>
                })
            }
            {
                [...Array(state.allowance - state.used)].map(function (object: any, i: number) {
                    return <Col key={"cccol/" + i}>
                        <Card key={"card/" + i} bg="dark" text="white" style={{ margin: '1em' }}>
                            <Card.Body>
                                <Card.Title>Available Slot</Card.Title>
                                <Card.Subtitle>Start your campaign now!</Card.Subtitle>
                            </Card.Body>
                            <Card.Footer>
                                <Button
                                    type="submit"
                                    className="sc_button"
                                    onClick={() => navigate("/new")}>
                                    <FaPlus style={{ marginRight: '1em' }} />
                                    Start a new Campaign
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                })
            }
        </Row>
    </Container>)
}

export default ScoreCards;
