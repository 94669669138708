// import '../App.css'
import { ChangeEvent, useRef, useState } from 'react';
import { FaCheck, FaSync } from 'react-icons/fa';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Trans } from 'react-i18next';

import AvatarEditor from 'react-avatar-editor';
import { ProgressBar } from 'react-bootstrap';
import Dropzone from 'react-dropzone';

type CanvasState = {
    base64String: string;
    blob: Blob;
};

const UploadResize = ({ dimension, setFileName }: { dimension: string, setFileName: React.Dispatch<any> }) => {
    const editor = useRef<AvatarEditor>(null);
    const [image, setImage] = useState<File>();
    const [previewImage, setPreviewImage] = useState<CanvasState>();
    const [scale, setScale] = useState<number>(1);
    const [rotation, setRotation] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadDone, setUploadDone] = useState<boolean>(false);
    const [uploadingPercent, setUploadingPercent] = useState<number>(0);
    const [uploadError, setUploadError] = useState<string>();

    let dx = dimension.split("x");
    let desiredWidth = parseInt(dx[0])
    let desiredHeight = parseInt(dx[1])

    let handleScale = (e: ChangeEvent<HTMLInputElement>) => {
        const scale = parseFloat(e.target.value)
        setScale(scale)
    }

    let rotateScale = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setRotation(parseFloat(e.target.value))
    }

    let handleReset = () => {
        setImage(undefined);
        setPreviewImage(undefined);
        setScale(1);
        setRotation(0);
        setUploading(false);
        setUploadingPercent(0);
    }

    let handleSave = () => {
        editor.current?.getImageScaledToCanvas().toBlob((blob: Blob | null) => {
            if (!blob) return;

            let img = editor.current?.getImageScaledToCanvas().toDataURL()
            if (!img) return

            setPreviewImage({
                base64String: img,
                blob: blob
            });
        });
    }

    let handleUpload = async () => {
        if (!previewImage) return;

        let signedUrl = "";
        let objectUrl = "";
        try {
            setUploading(true);
            let response = await fetch('https://metabeacon.win/api/signurl');
            let resObj = await response.json();

            signedUrl = resObj.signedUrl;
            objectUrl = resObj.objectUrl;
        } catch (e) {
            setUploading(false);
            setUploadError("could not get signed url for upload");
            return;
        }

        const method = 'put';
        const xhr = new XMLHttpRequest();
        xhr.open(method, signedUrl, true);

        // update progress (can be used to show progress indicator)
        xhr.upload.addEventListener('progress', e => {
            setUploadingPercent((e.loaded * 100.0) / e.total || 100)
        });

        xhr.addEventListener('readystatechange', async () => {
            // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
            if (xhr.readyState !== 2 && xhr.readyState !== 4) return

            if (xhr.status === 0) {
                setUploading(false);
                setUploadError(xhr.statusText);
            }

            if (xhr.status > 0 && xhr.status < 400) {
                setUploading(false);
                setFileName(objectUrl);
                setUploadDone(true);
            } else {
                setUploading(false);
                setUploadError(xhr.statusText);
            }
        });

        xhr.setRequestHeader('Content-Type', previewImage.blob.type);
        xhr.timeout = 120 * 60;
        xhr.send(previewImage.blob);
    }

    return (
        <Container className='p-2 mb-4 rounded-3'>
            {uploadDone && previewImage &&
                <img className='avatar-preview-img' src={previewImage.base64String} alt="preview" width="50%" />
            }
            {!uploadDone &&
                <Row>
                    <Dropzone
                        onDrop={files => setImage(files[0])}
                        maxFiles={1}
                        noKeyboard={true}
                        /*maxSize={1024 * 1024 * 1.5}*/
                        noClick={image !== undefined}
                        accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className="container">
                                <div
                                    {...getRootProps({
                                        className: 'dropzone',
                                        onDrop: event => event.stopPropagation()
                                    })}
                                >

                                    {!previewImage &&
                                        <Container>
                                            {!image &&
                                                <Container>
                                                    <p>
                                                        <Trans i18nKey="page_5_upload_message_top_click" />
                                                    </p>
                                                    <p className='muted-text'>
                                                        <Trans i18nKey="page_5_upload_message_bottom" />
                                                    </p>
                                                    <ul className='muted-text'>
                                                        <li>Image must be of size {dimension} px in png/jpg format</li>
                                                        <li>Maximum file size of 1.5 megabytes, smaller files load faster</li>
                                                    </ul>
                                                </Container>
                                            }


                                            {image &&
                                                <Row>
                                                    <Col className='avatar-col'>
                                                        < AvatarEditor
                                                            className='avatar-editor-canvas'
                                                            style={{
                                                                width: "100%",
                                                                height: "auto",
                                                                maxWidth: "100vh",
                                                                margin: "auto"
                                                            }}
                                                            ref={editor}
                                                            width={desiredWidth}
                                                            height={desiredHeight}
                                                            scale={scale}
                                                            rotate={rotation}
                                                            image={image} />
                                                    </Col>

                                                    <Col>
                                                        <br />
                                                        <Row>
                                                            <Button className="sc_button" onClick={handleReset}><FaSync /> Reset</Button>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <Col>
                                                                Zoom:
                                                            </Col>
                                                            <Col>
                                                                <input
                                                                    name="scale"
                                                                    type="range"
                                                                    onChange={handleScale}
                                                                    min="0.1"
                                                                    max="2"
                                                                    step="0.01"
                                                                    defaultValue="1"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                Rotation:
                                                            </Col>
                                                            <Col>
                                                                <input
                                                                    name="rotation"
                                                                    type="range"
                                                                    onChange={rotateScale}
                                                                    min="0"
                                                                    max="360"
                                                                    step="1"
                                                                    defaultValue="0"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <Button className="sc_button" onClick={handleSave}><FaCheck /> Continue</Button>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }
                                        </Container>
                                    }

                                    {previewImage &&
                                        <Container>
                                            <Row>
                                                <Col className='avatar-col'>
                                                    <img className='avatar-preview-img' src={previewImage.base64String} alt="preview" />
                                                </Col>
                                                <Col>
                                                    <p>
                                                        <Trans i18nKey="page_5_pre_upload_message" />
                                                    </p>
                                                    <p>
                                                        <Trans i18nKey="page_5_pre_upload_message_bottom" />
                                                    </p>
                                                    <Row>
                                                        <Button className="sc_button" onClick={handleReset}><FaSync /> Start over again</Button>
                                                    </Row>

                                                    <Row>
                                                        <Button className="sc_button" onClick={handleUpload} disabled={uploading}>
                                                            <FaCheck /> Upload {uploading && "in progress... Please wait."}</Button>
                                                    </Row>

                                                    <Row>
                                                        <br /><br />
                                                        {uploading &&
                                                            <ProgressBar
                                                                className='upload_progress'
                                                                animated now={uploadingPercent}
                                                                label={`${uploadingPercent}%`}></ProgressBar>
                                                        }

                                                        {uploadError &&
                                                            <p>error while uploading: {uploadError} - please try again.</p>
                                                        }
                                                    </Row>

                                                </Col>
                                            </Row>
                                        </Container>
                                    }

                                    <input {...getInputProps()} />


                                </div>
                            </div>
                        )}
                    </Dropzone>
                </Row>
            }
        </Container>
    );
}


export default UploadResize;

